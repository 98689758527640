<template>
    <div id="mastercard-landing-page">
        <BannerPartner />

        <div class="container p-4 p-md-5">
            <p class="m-0 text-center mb-4 h4 text-dark">Fique do lado seguro:</p>

            <ProductsCatalog :products="products" :add-product="addProduct" :open-cart="openCart" />

            <Platforms htmlClass="mt-5 text-center" />

            <div v-if="partner !== Partners.MASTERCARD" class="d-flex justify-content-center pb-3 ">
                <img src="@/assets/icons/cards.svg" class="d-inline-block mx-auto" alt="Bandeiras de cartões de crédito">
            </div>

            <p class="m-0 text-center small text-dark">* O preço mostrado corresponde ao primeiro ano. Consulte abaixo os detalhes da oferta.</p>
        </div>

        <div class="container d-flex justify-content-center">
            <ul class="list-group border-0">
                <li v-for="(item, index) in checkLlist" :key="index" class="list-group-item border-0" :class="{ 'text-danger': index === 0 }">
                    <img src="@/assets/mastercard-partner/img/svg/checkmark-outline.svg" alt="icone de check" width="25"> {{ item }}
                </li>
            </ul>
        </div>

        <div class="mt-3 container">
            <Carousel />
        </div>

        <div class="mt-3 container">
            <div class="row">
                <div class="col col-12 col-md-6">
                    <img class="img-fluid" src="@/assets/img/concluida-carredura.png" alt="Homem sorrindo segurando um tablet">
                </div>

                <div class="col col-12 col-md-4 offset-md-1 pt-5">
                    <h2>É mais do que apenas um software antivírus, é <span class="text-danger">tranquilidade</span>
                    </h2>
                    <p class="m-0 text-dark">Defenda você e a toda a família contra os mais recentes ataques de vírus, malware, spyware e ransomware, ao mesmo tempo em que mantém o controle de sua privacidade e identidade.</p>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column flex-md-row align-items-center justify-content-center gap-5 p-5 bg-light mt-3">
            <img src="@/assets/img/logos/mcafee-escudo.svg" alt="icone da logo da McAfee">
            <p class="h3 fw-light"><b class="fw-bold">Milhares de clientes</b> compram, jogam e navegam <br /> online com segurança. <b class="fw-bold">Seja um deles!</b></p>
        </div>

        <WhyMcAfee />

        <div class="mt-3 container p-5">
            <div class="row">
                <div class="col col-12 col-md-6">
                    <img class="img-fluid" src="@/assets/img/devices.png" alt="A imagem mostra um laptop ou notebook, e um smartphone">
                </div>

                <div class="col col-12 col-md-4 offset-md-1 pt-5">
                    <h2><b class="fw-bold text-danger">Tranquilidade</b> para cada PC, telefone e tablet</h2>
                    <p class="m-0 text-dark my-4">Proteja todos os seus dispositivos com uma assinatura que cobre todos os dispositivos em sua casa. Essa é a proteção que pode ajudar a manter toda a família segura.</p>
                    <Platforms />
                </div>
            </div>
        </div>

        <div class="bg-light">
            <div class="container p-5">
                <div class="row">
                    <div v-for="(section, index) in section2Rows2Columns" :key="index" class="col col-12 col-md-6 p-4 p-md-5">
                        <img :src="section.icon" :alt="section.title" />
                        <p class="fw-bold text-dark h5 mt-2">{{ section.title }}</p>
                        <p class="m-0 text-dark">{{ section.description }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-4 p-md-5 w-100 mb-5 d-flex flex-column align-items-center">
            <p class="h3 text-dark fw-normal text-center mb-4">Ative sua proteção <b class="fw-bold text-danger">hoje</b></p>

            <p class="text-dark text-center">Ao ativar sua assinatura adicionaremos os dias restantes da sua avaliação a ela, assim você não perderá nenhum momento da proteção grátis.</p>

            <ProductsCatalog :products="products" :add-product="addProduct" :open-cart="openCart" />
        </div>

        <Faq />
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from "vue";
    import { useStore } from "vuex";

    import BannerPartner from "@/components/BannerPartner";

    import { checkLlist, section2Rows2Columns } from "./sections";
    import ProductsCatalog from "@/components/Sale/ProductsCatalog";
    import Carousel from "@/components/Carousel/Carousel.vue";
    import WhyMcAfee from "@/components/WhyMcAfee";
    import Faq from "@/modules/landingPages/mcafee/components/Faq";
    import Platforms from "@/modules/landingPages/mcafee/components/Platforms";

    import Configuration from "@/services/configuration";
    import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
    import Partners from "@/constants/partner/partner.constants";

    // * setup
    const store = useStore();

    // * props
    const products = computed(() => store.getters['saleFlow/product/getProducts']);
    const partner = ref('');

    // * methods
    function addProduct({ product, promotion }) {
        store.dispatch('cart/addProductCart', { product, promotion });
    }

    function openCart() {
        store.dispatch('cart/openCart');
    }

    async function getProducts() {
        const productType = partner.value === Partners.MASTERCARD ? (
            productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION_MASTERCARD_PARTNERSHIP
        ) : (
            productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION
        );

        await store.dispatch('saleFlow/product/setAvailableProductType', [
            Configuration.productTypeOption[productType]
        ]);

        store.dispatch('saleFlow/getAllProductsCatalog');
    }

    onMounted(async () => {
        partner.value = store.getters.getPartner;
        store.dispatch('address/setNoClaroFields');
        await getProducts();
    });
</script>

<style lang="scss">
    #header > div {
        padding: 1rem;
    }
</style>

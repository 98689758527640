export const checkLlist = [
  "Agora você pode parcelar sua assinatura em até 12x sem juros.",
  "Proteja seus Dispositivos. Compatível com Windows, Mac, iOS e Android.",
  "1 ano completo de proteção para você e seus dispositivos.",
  "Proteja sua família contra vírus, malware e ameaças online.",
];


export const section2Rows2Columns = [
  {
    icon: require('@/assets/icons/key-password.svg'),
    title: 'Gerenciador de senhas',
    description: 'Cria senhas fortes e exclusivas para todas as suas contas on-line, além de salvar e lembrar delas, para que você não precise fazer isso.',
  },
  {
    icon: require('@/assets/icons/24-7-protection.svg'),
    title: 'Proteção contra ameaças em tempo real',
    description: 'Proteção 24 horas por dia, 7 dias por semana para todos os dispositivos contra malware e ameaças on-line em constante evolução.',
  },
  {
    icon: require('@/assets/icons/trofeu-check.svg'),
    title: 'Antivírus premiado',
    description: 'A análise de ameaças baseada em nuvem mantém você protegido contra vírus, incluindo ransomware, sem atrasá-lo.',
  },
  {
    icon: require('@/assets/icons/mao-effect.svg'),
    title: 'Fácil de usar',
    description: 'Um console fácil de usar baseado na Web permite proteger e gerenciar todos os seus dispositivos em um só lugar.',
  },
]
